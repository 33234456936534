// import React from 'react'

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true
//     })
//   }
// }

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // Without this hack on every query string update because of calculator input change react router will scroll to top.
  // It's frustrating on mobile devices where content don't fit into one screen by height.
  if (prevRouterProps && routerProps && prevRouterProps.location.pathname === routerProps.location.pathname) {
    return false
  }
  return true
}
