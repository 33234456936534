// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-custom-url-pages-calculator-with-inflation-tsx": () => import("./../../../src/custom-url-pages/calculator-with-inflation.tsx" /* webpackChunkName: "component---src-custom-url-pages-calculator-with-inflation-tsx" */),
  "component---src-custom-url-pages-compare-with-rent-tsx": () => import("./../../../src/custom-url-pages/compare-with-rent.tsx" /* webpackChunkName: "component---src-custom-url-pages-compare-with-rent-tsx" */),
  "component---src-custom-url-pages-formula-tsx": () => import("./../../../src/custom-url-pages/formula.tsx" /* webpackChunkName: "component---src-custom-url-pages-formula-tsx" */),
  "component---src-custom-url-pages-prepayments-calculator-tsx": () => import("./../../../src/custom-url-pages/prepayments-calculator.tsx" /* webpackChunkName: "component---src-custom-url-pages-prepayments-calculator-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

